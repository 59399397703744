//React
import React from 'react'
import { Cookies } from 'react-cookie'
import styled from 'styled-components'

//Pages
import Error from 'pages/Error';

function Pages() {

  const cookies = new Cookies();
  const token = cookies.get('dlrjsxhzmsdlqslek');
  const avatarId = cookies.get('app-avatarId');
  const target = new URL(window.location.href).searchParams.get('target');

  if(!token){
    window.location.replace('https://www2.creator.ly/login');
  }

  return (
    <Iframe src={token? `https://cafe.meercat.io/?target=${target}&avatarId=${avatarId}&token=${token}` : `https://cafe.meercat.io/?target=${target}&avatarId=${avatarId}`} />
  )

}

export default Pages;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`