//React
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//Components
import ApplicationLayout from 'components/ApplicationLayout';
import Pages from 'pages';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<ApplicationLayout><Pages/></ApplicationLayout>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;