//React
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie'
import styled from 'styled-components';

//Assets
import headerCreatorly from 'assets/icons/application-header-creatorly.svg';
import headerHelp from 'assets/icons/application-header-help.svg';
import headerHelpIcon from 'assets/icons/application-header-help-icon.svg';
import headerProfile from 'assets/icons/application-header-profile.svg';
import headerLogout from 'assets/icons/application-header-logout.svg';
import menuLink from 'assets/icons/application-menu-link.svg';
import menuCampaign from 'assets/icons/application-menu-campaign.svg';
import menuAnalysis from 'assets/icons/application-menu-analysis.svg';
import menuCommunity from 'assets/icons/application-menu-community.svg';
import menuSettings from 'assets/icons/application-menu-settings.svg';
import buttonClose from 'assets/icons/button-close.svg';

//Components
import { 
  FlexBox,
  H7,
  T2,
  E2,
  MainBlue,
  NextButton
} from 'styles/DesignSystem';

const loginLink = 'https://www2.creator.ly/login';
const linktreeLink = 'https://www2.creator.ly/app/link';
const campaignLink = 'https://www2.creator.ly/app/campaign';
const analysisLink = 'https://analysis.creator.ly';
const settingsLink = 'https://www2.creator.ly/app/settings';
const changePasswordLink = 'https://www2.creator.ly/app/settings?initialize_target=change_password';
const deleteUserLink = 'https://www2.creator.ly/app/settings?initialize_target=delete_user';

function ApplicationLayout({ children }) {

  const cookies = new Cookies();
  const avatarId = cookies.get('app-avatarId');
  const integrateId = cookies.get('app-integrateId');
  const token = cookies.get('dlrjsxhzmsdlqslek');
  if (!token) {
    window.location.href = loginLink;
  }

  const navigate = useNavigate();

  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [isProfileDrop, setIsProfileDrop] = useState(false);
  const [isDropDownHover, setIsDropDownHover] = useState(false);
  const [isLinktreeNotice, setIsLinktreeNotice] = useState(false);

  useEffect(function () {

    const handleResize = function () {
      setInnerHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      window.removeEventListener('resize', handleResize);
    }

  }, [])

  const handleCloseOnclickContainer = function(){
    if(isProfileDrop && !isDropDownHover){
      setIsProfileDrop(false);
    }
  }
  const handleProfileOnclick = function(){
    setIsProfileDrop(current => !current);
  }
  const handleDropDownMouseEnter = function(){
    setIsDropDownHover(true);
  }
  const handleDropDownMouseLeave = function(){
    setIsDropDownHover(false);
  }

  const handleLogoutOnclick = function () { // 로그아웃
    cookies.remove('app-avatarId', { path: '/', domain: '.creator.ly' });
    cookies.remove('app-integrateId', { path: '/', domain: '.creator.ly' });
    cookies.remove('dlrjsxhzmsdlqslek', { path: '/', domain: '.creator.ly' });
    window.sessionStorage.removeItem('userData');
    window.sessionStorage.removeItem('instagramData');
    window.sessionStorage.removeItem('youtubeData');
    window.location.replace('https://creator.ly');
  }

  const sideBarMenuOnclick = function(link){
    if(link===linktreeLink){
      setIsLinktreeNotice(true);
    } else {
      window.location.href = link;
    }
  }

  return (
    <Container onClick={handleCloseOnclickContainer} >

      <HeaderWrapper>
        <LogoIcon src={headerCreatorly} onClick={function(){ navigate(`/`); }}/>
        <FlexBox/>
        <HelpIcon src={headerHelp} onClick={function () { window.ChannelIO('showMessenger') }}/>
        <ProfileIcon src={headerProfile} onClick={handleProfileOnclick} />
        {/* <ProfileIcon src='추후 통합 프로필 생기면 => 쿠키 통합 프로필' onError={function(e){ e.target.src = profileIcon;}}/> */}

        {isProfileDrop &&
        <DropDownContainer onMouseEnter={handleDropDownMouseEnter} onMouseLeave={handleDropDownMouseLeave}>
        <ProfileFlexBox justify={'space-between'} style={{width: '100%', padding: 20, borderBottom: '1px solid #F6F6F6'}}>
          <ProfileText size={'18px'} weight={700} style={(avatarId)?{}:{cursor: 'pointer', color:'#4480F7', fontSize:'16px', height:'24px', display: 'flex', alignItems: 'center'}}
            onClick={function () { if(!avatarId) window.location.href = loginLink; }}
          >{avatarId ? avatarId : '통합 로그인 하기'}</ProfileText>
          <ProfileText size={'14px'} weight={400} color={'#4480F7'}>{avatarId && '통합회원'}</ProfileText>
        </ProfileFlexBox>
        <ProfileFlexBoxMobile flow={'column nowrap'} align={'flex-start'} style={{borderTop: '1px solid #F6F6F6'}}>
          <DropDownText size={'14px'} color={'#555555'} 
            style={{
                cursor: 'pointer', 
                padding: '20px 0 20px 20px', 
                width:'100%',
                display: 'flex',
                alignItems: 'center',
            }} 
            onClick={function () { window.ChannelIO('showMessenger') }}
          >
            <img src={headerHelpIcon} alt="" style={{width:'16px', marginRight:'5px'}}/>
            1:1 문의하기
          </DropDownText>
        </ProfileFlexBoxMobile>
        <ProfileFlexBox flow={'column nowrap'} align={'flex-start'} style={{borderTop: '1px solid #F6F6F6'}}>
          <DropDownText size={'14px'} color={'#555555'} 
            style={{
                cursor: 'pointer', 
                padding: '20px 0 20px 20px', 
                width:'100%',
                display: 'flex',
                alignItems: 'center',
            }} 
            onClick={handleLogoutOnclick}
          >
            <img src={headerLogout} alt="" style={{width:'16px', marginRight:'5px'}}/>
            로그아웃
          </DropDownText>
        </ProfileFlexBox>
      </DropDownContainer>}
      </HeaderWrapper>

      <DesktopSidebarWrapper>
        <SidebarRow onClick={function(){ sideBarMenuOnclick(linktreeLink); }}>
          <SidebarIcon src={menuLink}/>
          <T2 color="#555555">링크 관리</T2>
        </SidebarRow>
        
        <SidebarRow onClick={function(){ sideBarMenuOnclick(campaignLink); }}>
          <SidebarIcon src={menuCampaign}/>
          <T2 color="#555555">캠페인 관리</T2>
        </SidebarRow>

        <SidebarRow onClick={function(){ sideBarMenuOnclick(analysisLink); }}>
          <SidebarIcon src={menuAnalysis}/>
          <T2 color="#555555">분석 리포트</T2>
        </SidebarRow>

        <SidebarRow style={{ background: '#ECF2FE' }} whetherSellected>
          <SidebarIcon src={menuCommunity} style={{ filter: 'brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)'}}/>
          <T2 bold><MainBlue>커뮤니티</MainBlue></T2>
        </SidebarRow>

        <FlexBox/>

        <SidebarRow onClick={function(){ sideBarMenuOnclick(settingsLink); }}>
          <SidebarIcon src={menuSettings} style={{width:'17px', height:'17px'}}/>
          <T2 color="#555555">계정 설정</T2>
        </SidebarRow>
      </DesktopSidebarWrapper>

      <ContentWrapper innerHeight={innerHeight}>
        {children}
      </ContentWrapper>

      <BottomTapWrapper>
        <BottomColumn  onClick={function(){ sideBarMenuOnclick(linktreeLink); }}>
          <SidebarIcon src={menuLink}/>
          <E2 color="#555555">링크 관리</E2>
        </BottomColumn>

        <BottomColumn  onClick={function(){ sideBarMenuOnclick(campaignLink); }}>
          <SidebarIcon src={menuCampaign}/>
          <E2 color="#555555">캠페인 관리</E2>
        </BottomColumn>

        <BottomColumn onClick={function(){ sideBarMenuOnclick(analysisLink); }}>
          <SidebarIcon src={menuAnalysis}/>
          <E2 color="#555555">분석 리포트</E2>
        </BottomColumn>

        <BottomColumn whetherSellected>
          <SidebarIcon src={menuCommunity} style={{ filter: 'brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)'}}/>
          <E2><MainBlue>커뮤니티</MainBlue></E2>
        </BottomColumn>

        <BottomColumn  onClick={function(){ sideBarMenuOnclick(settingsLink); }}>
          <SidebarIcon src={menuSettings}/>
          <E2 color="#555555">계정 설정</E2>
        </BottomColumn>

      </BottomTapWrapper>

      {
        isLinktreeNotice
        && <IntegratePopUpBackground innerHeight={innerHeight}>
          <IntegratePopUpContainer>
            <IntegratePopUpCloseIcon src={buttonClose} onClick={function () { setIsLinktreeNotice(false); }} />
            <H7 bold color='#111111'>현재 개발중인 서비스입니다</H7>
            <ReversedT2 top={10} color='#777777'>
              빠른 시일 내에 서비스를 제공할 수 있도록<br/>
              크리에이터리 개발팀이 노력하겠습니다
            </ReversedT2>
            <FlexBox />
            <IntegratePopUpNextButton active onClick={function () { setIsLinktreeNotice(false); }}><ReversedT2>닫기</ReversedT2></IntegratePopUpNextButton>
          </IntegratePopUpContainer>
        </IntegratePopUpBackground>
      }

    </Container>
  )
}

export default ApplicationLayout

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #FFFFFF;

  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
`

const HeaderWrapper = styled.header`
  width: 100%;
  height: 80px;
  padding: 0px 30px 0px 30px;
  border-bottom: 1px solid #F6F6F6;
  background: #FFFFFF;

  display: flex;
  align-items: center;
  position: fixed;
  top: 0;

  z-index: 4;

  @media (max-width: 590px){
    height: 60px;
    padding: 0px 20px;
  }
`

const DesktopSidebarWrapper = styled.div`
  width: 200px;
  height: calc(100vh - 80px);
  padding: 29.5px 0 60.5px 0;
  border-right: 1px solid #F6F6F6;

  gap: 10px;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  position: absolute;
  top: 80px;
  left: 0px;

  @media (max-width: 590px){
    display: none;
  }
`

const ContentWrapper = styled.div`
  width: calc(100vw - 200px);
  height: calc(100vh - 80px);
  overflow-y: auto;
  position: fixed;
  margin-top: 80px;
  margin-left: 200px;

  @media (max-width: 590px){
    width: 100vw;
    height: ${props => `${props.innerHeight-130}px` };
    margin-top: 60px;
    margin-left: 0px;
  }

  ::-webkit-scrollbar{
    display: none;
  }
`

const LogoIcon = styled.img`
  width: 128px;
  cursor: pointer;

  @media (max-width: 590px){
    width: 120px;
  }
`

const HelpIcon = styled.img`
  width: 140px;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 590px){
    display: none;
  }
`

const ProfileIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translate(50, 50);
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 590px){
    width: 28px;
    height: 28px;
  }
`

//================================================ Profile Dropdown Components

const DropDownContainer = styled.div`
  width: 260px;
  border-radius: 5px;
  background: white;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  right: 30px;
  top: 78px;
  z-index: 5;

  @media (max-width: 590px){
    top: 59px;
    right: 20px;
    width: 240px;
  }
`

const ProfileFlexBox = styled.div`
  display: flex;
  justify-content: ${props => props.justify ?? 'center'};
  align-items: ${props => props.align ?? 'center'};
  gap: ${props => props.gap ?? '0'}px;
  flex-flow: ${props => props.flow ?? ''};
`

const ProfileFlexBoxMobile = styled.div`
  display: none;
  justify-content: ${props => props.justify ?? 'center'};
  align-items: ${props => props.align ?? 'center'};
  gap: ${props => props.gap ?? '0'}px;
  flex-flow: ${props => props.flow ?? ''};

  @media (max-width: 590px){
    display: flex;
  }
`

const ProfileText = styled.div`
  font-size: ${props => props.size ?? '18px'};
  font-weight: ${props => props.weight ?? '18px'};
  color: ${props => props.color ?? 'black'};
  line-height: ${props => props.lineHeight ?? '130%'};
  opacity: ${props => props.opacity ?? '1'};
  user-select: none;

  @media (max-width: 590px){
    font-size: ${props => props.mobileSize ? props.mobileSize : props.size ?? '18px'};
  }
`

const DropDownText = styled(ProfileText)`
  &:hover{
    filter: ${(props) => props.whetherSellected? '' : 'brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)'};
  }

  @media (max-width: 590px){
    &:hover{
      filter: none;
    }
  }
`

//================================================ End Profile Dropdown Components

const SidebarRow = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover{
    filter: ${(props) => props.whetherSellected? '' : 'brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)'};
  }
`;

const SidebarIcon  = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;

  @media (max-width: 590px){
    width: 20px;
    height: 20px;
    margin: 0 0 5px 0;
  }
`

const IntegratePopUpBackground  = styled.div`
  width: 100vw;
  height: ${props => `${props.innerHeight}px`};
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 5;

  @media (max-width: 590px){
    margin-left: 0px;
  }
`

const IntegratePopUpContainer = styled.div`
  width: 400px;
  height: 290px;
  padding: 80px 30px 30px;
  background: #FCFCFC;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 590px){
    width: 320px;
    height: 245px;
    padding: 60px 20px 20px;
  }
`

const IntegratePopUpCloseIcon  = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 30px;
  right: 30px;
  cursor: pointer;

  @media (max-width: 590px){
    top: 20px;
    right: 20px;
  }
`

const IntegratePopUpNextButton = styled(NextButton)`
  width: 340px;
  height: 60px;

  @media (max-width: 590px){
    width: 280px;
    height: 56px;
  }
`

const BottomTapWrapper = styled.header`
  display: none;

  @media (max-width: 590px){
    width: 100%;
    height: 70px;
    border-top: 1px solid #EEEEEE;
    background: #FFFFFF;
    padding: 0px 10px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
  }
`

const BottomColumn = styled.div`
  width: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover{
    filter: ${(props) => props.whetherSellected? '' : 'brightness(0) saturate(100%) invert(49%) sepia(81%) saturate(3967%) hue-rotate(207deg) brightness(102%) contrast(94%)'};
  }
`;

const ReversedT2 = styled(T2)`
  line-height: 160%;
  text-align: center;

  @media (max-width: 590px){
    font-size: 16px;
  }
`;