import styled from "styled-components"

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};
    gap: ${(props) => `${props.gap}px`};
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};
    gap: ${(props) => `${props.gap}px`};
`;

export const FlexBox = styled.div`
    display: flex;
    flex: 1;
`;

export const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-top: ${(props) => `${props.top}px`};
  margin-bottom: ${(props) => `${props.bottom}px`};
  margin-left: ${(props) => `${props.left}px`};
  margin-right: ${(props) => `${props.right}px`};
  border-radius: ${(props) => props.borderRadius};
`;

export const H1 = styled.p`
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 36px;
        line-height: 46px;
    }
`

export const H2 = styled.p`
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 32px;
        line-height: 42px;
    }
`

export const H3 = styled.p`
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 28px;
        line-height: 36px;
    }
`

export const H4 = styled.p`
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 25px;
        line-height: 32px;
    }
`

export const H5 = styled.p`
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 22px;
        line-height: 28px;
    }
`

export const H6 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 22px;
    line-height: 28px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 20px;
        line-height: 26px;
    }
`

export const H7 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 20px;
    line-height: 26px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 18px;
        line-height: 24px;
    }
`

export const T1 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 16px;
        line-height: 20px;
    }
`

export const T2 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 14px;
        line-height: 18px;
    }
`

export const B1 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 12px;
        line-height: 16px;
    }
`

export const B2 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 11px;
        line-height: 14px;
    }
`

export const E1 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 11px;
    line-height: 14px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};

    @media (max-width: 590px){
        font-size: 10px;
        line-height: 14px;
    }
`

export const E2 = styled.p`
    font-weight: ${(props) => props.bold ? '700' : '400'};
    font-size: 10px;
    line-height: 14px;
    color: ${(props) => props.color};
    margin-top: ${(props) => `${props.top}px`};
    margin-left: ${(props) => `${props.left}px`};
`

export const MainBlue = styled.span`
    color: #4480F7;
`

export const ActionButton = styled.button`
    width: ${(props) => props.width || '320px'};
    height: ${(props) => props.height || '60px'};
    background: #FFFFFF;
    border: 1px solid #F6F6F6;
    border-radius: 5px;
    margin-top: ${(props) => `${props.top}px`};

    &:hover,
    &:active{
        background: #ECF2FE;
        border: 1px solid #4480F7;
    }
`

export const NextButton = styled.button`
    width: ${(props) => props.width? `${props.width}px`: '320px'};
    height: ${(props) => props.height? `${props.height}px`: '60px'};
    background: ${(props) => props.active ? '#4480F7' : '#E6E6E6'};
    border-radius: 5px;
    color: #FFFFFF;
    margin-top: ${(props) => `${props.top}px`};

    &:hover,
    &:active{
        background: #3470E7;
    }
`